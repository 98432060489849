
import { defineComponent } from 'vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';

export default defineComponent({
  components: { Icon },
  setup() {
    return {
      ICON_THEMES,
    };
  },
});
